/**
 * The list of all available MUI icons.
 * @see https://mui.com/material-ui/material-icons/
 */
const IconList = {
    123: "123",
    360: "360",
    search: "search",
    home: "home",
    menu: "menu",
    close: "close",
    settings: "settings",
    expandMore: "expand_more",
    done: "done",
    checkCircle: "check_circle",
    favorite: "favorite",
    add: "add",
    delete: "delete",
    arrowBack: "arrow_back",
    star: "star",
    chevronRight: "chevron_right",
    logout: "logout",
    arrowForwardIos: "arrow_forward_ios",
    addCircle: "add_circle",
    cancel: "cancel",
    arrowBackIos: "arrow_back_ios",
    fileDownload: "file_download",
    arrowForward: "arrow_forward",
    arrowDropDown: "arrow_drop_down",
    moreVert: "more_vert",
    check: "check",
    checkBox: "check_box",
    toggleOn: "toggle_on",
    grade: "grade",
    openInNew: "open_in_new",
    checkBoxOutlineBlank: "check_box_outline_blank",
    refresh: "refresh",
    login: "login",
    chevronLeft: "chevron_left",
    expandLess: "expand_less",
    radioButtonUnchecked: "radio_button_unchecked",
    moreHoriz: "more_horiz",
    arrowRightAlt: "arrow_right_alt",
    apps: "apps",
    radioButtonChecked: "radio_button_checked",
    fileUpload: "file_upload",
    download: "download",
    remove: "remove",
    toggleOff: "toggle_off",
    bolt: "bolt",
    arrowUpward: "arrow_upward",
    filterList: "filter_list",
    deleteForever: "delete_forever",
    autorenew: "autorenew",
    key: "key",
    sort: "sort",
    arrowDownward: "arrow_downward",
    addBox: "add_box",
    sync: "sync",
    arrowBackIosNew: "arrow_back_ios_new",
    block: "block",
    restartAlt: "restart_alt",
    menuOpen: "menu_open",
    shoppingCartCheckout: "shopping_cart_checkout",
    expandCircleDown: "expand_circle_down",
    backspace: "backspace",
    arrowCircleRight: "arrow_circle_right",
    undo: "undo",
    doneAll: "done_all",
    arrowRight: "arrow_right",
    doubleArrow: "double_arrow",
    doNotDisturbOn: "do_not_disturb_on",
    openInFull: "open_in_full",
    manageSearch: "manage_search",
    syncAlt: "sync_alt",
    zoomIn: "zoom_in",
    doneOutline: "done_outline",
    dragIndicator: "drag_indicator",
    fullscreen: "fullscreen",
    keyboardDoubleArrowRight: "keyboard_double_arrow_right",
    starHalf: "star_half",
    settingsAccessibility: "settings_accessibility",
    arrowDropUp: "arrow_drop_up",
    iosShare: "ios_share",
    reply: "reply",
    exitToApp: "exit_to_app",
    unfoldMore: "unfold_more",
    libraryAdd: "library_add",
    cached: "cached",
    selectCheckBox: "select_check_box",
    terminal: "terminal",
    disabledByDefault: "disabled_by_default",
    changeCircle: "change_circle",
    swapHoriz: "swap_horiz",
    swapVert: "swap_vert",
    arrowCircleLeft: "arrow_circle_left",
    downloadForOffline: "download_for_offline",
    appRegistration: "app_registration",
    arrowCircleUp: "arrow_circle_up",
    closeFullscreen: "close_fullscreen",
    fileOpen: "file_open",
    minimize: "minimize",
    keyboardDoubleArrowLeft: "keyboard_double_arrow_left",
    openWith: "open_with",
    keyboardDoubleArrowDown: "keyboard_double_arrow_down",
    dataset: "dataset",
    addTask: "add_task",
    createNewFolder: "create_new_folder",
    start: "start",
    keyboardVoice: "keyboard_voice",
    forward: "forward",
    settingsApplications: "settings_applications",
    downloading: "downloading",
    arrowLeft: "arrow_left",
    compareArrows: "compare_arrows",
    zoomOut: "zoom_out",
    redo: "redo",
    publish: "publish",
    html: "html",
    token: "token",
    switchAccessShortcut: "switch_access_shortcut",
    arrowCircleDown: "arrow_circle_down",
    fullscreenExit: "fullscreen_exit",
    sortByAlpha: "sort_by_alpha",
    deleteSweep: "delete_sweep",
    indeterminateCheckBox: "indeterminate_check_box",
    firstPage: "first_page",
    keyboardDoubleArrowUp: "keyboard_double_arrow_up",
    viewTimeline: "view_timeline",
    arrowDropDownCircle: "arrow_drop_down_circle",
    settingsBackupRestore: "settings_backup_restore",
    assistantNavigation: "assistant_navigation",
    clearAll: "clear_all",
    syncProblem: "sync_problem",
    densityMedium: "density_medium",
    filterAltOff: "filter_alt_off",
    lastPage: "last_page",
    heartPlus: "heart_plus",
    subdirectoryArrowRight: "subdirectory_arrow_right",
    expand: "expand",
    downloadDone: "download_done",
    unfoldLess: "unfold_less",
    arrowOutward: "arrow_outward",
    swipeLeft: "swipe_left",
    savedSearch: "saved_search",
    placeItem: "place_item",
    systemUpdateAlt: "system_update_alt",
    autoMode: "auto_mode",
    maximize: "maximize",
    javascript: "javascript",
    searchOff: "search_off",
    selectAll: "select_all",
    swipeUp: "swipe_up",
    output: "output",
    fitScreen: "fit_screen",
    dynamicForm: "dynamic_form",
    hideSource: "hide_source",
    swipeRight: "swipe_right",
    switchAccessShortcutAdd: "switch_access_shortcut_add",
    css: "css",
    browseGallery: "browse_gallery",
    densitySmall: "density_small",
    assistantDirection: "assistant_direction",
    fileDownloadDone: "file_download_done",
    youtubeSearchedFor: "youtube_searched_for",
    moveUp: "move_up",
    checkSmall: "check_small",
    swapHorizontalCircle: "swap_horizontal_circle",
    installMobile: "install_mobile",
    moveDown: "move_down",
    dataThresholding: "data_thresholding",
    restoreFromTrash: "restore_from_trash",
    datasetLinked: "dataset_linked",
    abc: "abc",
    enable: "enable",
    installDesktop: "install_desktop",
    keyboardCommandKey: "keyboard_command_key",
    viewKanban: "view_kanban",
    replyAll: "reply_all",
    switchLeft: "switch_left",
    browseActivity: "browse_activity",
    compress: "compress",
    swipeDown: "swipe_down",
    swapVerticalCircle: "swap_vertical_circle",
    removeDone: "remove_done",
    filterListOff: "filter_list_off",
    appsOutage: "apps_outage",
    switchRight: "switch_right",
    hide: "hide",
    swipeVertical: "swipe_vertical",
    pinch: "pinch",
    moreUp: "more_up",
    keyboardControlKey: "keyboard_control_key",
    syncDisabled: "sync_disabled",
    eject: "eject",
    keyOff: "key_off",
    php: "php",
    subdirectoryArrowLeft: "subdirectory_arrow_left",
    starRate: "star_rate",
    viewCozy: "view_cozy",
    sendTimeExtension: "send_time_extension",
    doNotDisturbOff: "do_not_disturb_off",
    widthNormal: "width_normal",
    transcribe: "transcribe",
    viewComfyAlt: "view_comfy_alt",
    heartMinus: "heart_minus",
    widthFull: "width_full",
    shareReviews: "share_reviews",
    viewCompactAlt: "view_compact_alt",
    unfoldMoreDouble: "unfold_more_double",
    fileDownloadOff: "file_download_off",
    extensionOff: "extension_off",
    openInNewOff: "open_in_new_off",
    vpnKeyOff: "vpn_key_off",
    moreDown: "more_down",
    checkIndeterminateSmall: "check_indeterminate_small",
    widthWide: "width_wide",
    repartition: "repartition",
    swipeLeftAlt: "swipe_left_alt",
    densityLarge: "density_large",
    swipeDownAlt: "swipe_down_alt",
    swipeRightAlt: "swipe_right_alt",
    swipeUpAlt: "swipe_up_alt",
    unfoldLessDouble: "unfold_less_double",
    keyboardOptionKey: "keyboard_option_key",
    hls: "hls",
    hlsOff: "hls_off",
    fileUploadOff: "file_upload_off",
    cycle: "cycle",
    rebase: "rebase",
    emptyDashboard: "empty_dashboard",
    rebaseEdit: "rebase_edit",
    person: "person",
    group: "group",
    share: "share",
    thumbUp: "thumb_up",
    groups: "groups",
    personAdd: "person_add",
    public: "public",
    supportAgent: "support_agent",
    face: "face",
    handshake: "handshake",
    sentimentSatisfied: "sentiment_satisfied",
    rocketLaunch: "rocket_launch",
    groupAdd: "group_add",
    workspacePremium: "workspace_premium",
    psychology: "psychology",
    personFilled: "person_filled",
    waterDrop: "water_drop",
    eco: "eco",
    pets: "pets",
    emojiObjects: "emoji_objects",
    travelExplore: "travel_explore",
    diversity3: "diversity_3",
    mood: "mood",
    sunny: "sunny",
    quiz: "quiz",
    healthAndSafety: "health_and_safety",
    sentimentDissatisfied: "sentiment_dissatisfied",
    sentimentVerySatisfied: "sentiment_very_satisfied",
    militaryTech: "military_tech",
    recycling: "recycling",
    thumbDown: "thumb_down",
    gavel: "gavel",
    diamond: "diamond",
    monitorHeart: "monitor_heart",
    emojiPeople: "emoji_people",
    vaccines: "vaccines",
    workspaces: "workspaces",
    diversity1: "diversity_1",
    thumbUpOff: "thumb_up_off",
    compost: "compost",
    forest: "forest",
    wavingHand: "waving_hand",
    personRemove: "person_remove",
    recommend: "recommend",
    wc: "wc",
    medication: "medication",
    groupWork: "group_work",
    sentimentVeryDissatisfied: "sentiment_very_dissatisfied",
    sentimentNeutral: "sentiment_neutral",
    diversity2: "diversity_2",
    frontHand: "front_hand",
    man: "man",
    crueltyFree: "cruelty_free",
    medicalInformation: "medical_information",
    coronavirus: "coronavirus",
    rocket: "rocket",
    psychologyAlt: "psychology_alt",
    addReaction: "add_reaction",
    female: "female",
    pottedPlant: "potted_plant",
    emojiNature: "emoji_nature",
    rainy: "rainy",
    personOff: "person_off",
    woman: "woman",
    male: "male",
    connectWithoutContact: "connect_without_contact",
    cookie: "cookie",
    moodBad: "mood_bad",
    solarPower: "solar_power",
    bedtime: "bedtime",
    thunderstorm: "thunderstorm",
    communication: "communication",
    groups2: "groups_2",
    partlyCloudyDay: "partly_cloudy_day",
    cloudy: "cloudy",
    thumbsUpDown: "thumbs_up_down",
    masks: "masks",
    emojiFlags: "emoji_flags",
    hive: "hive",
    heartBroken: "heart_broken",
    sentimentExtremelyDissatisfied: "sentiment_extremely_dissatisfied",
    clearDay: "clear_day",
    boy: "boy",
    whatshot: "whatshot",
    emojiFoodBeverage: "emoji_food_beverage",
    emojiTransportation: "emoji_transportation",
    windPower: "wind_power",
    elderly: "elderly",
    thumbDownOff: "thumb_down_off",
    face6: "face_6",
    vpnLock: "vpn_lock",
    reduceCapacity: "reduce_capacity",
    sick: "sick",
    pregnantWoman: "pregnant_woman",
    bloodtype: "bloodtype",
    groupRemove: "group_remove",
    medicationLiquid: "medication_liquid",
    egg: "egg",
    face3: "face_3",
    co2: "co2",
    weight: "weight",
    groups3: "groups_3",
    followTheSigns: "follow_the_signs",
    skull: "skull",
    transgender: "transgender",
    oilBarrel: "oil_barrel",
    elderlyWoman: "elderly_woman",
    face4: "face_4",
    cleanHands: "clean_hands",
    sanitizer: "sanitizer",
    bringYourOwnIp: "bring_your_own_ip",
    person2: "person_2",
    cloudyFilled: "cloudy_filled",
    socialDistance: "social_distance",
    publicOff: "public_off",
    signLanguage: "sign_language",
    southAmerica: "south_america",
    sunnySnowing: "sunny_snowing",
    face2: "face_2",
    emojiSymbols: "emoji_symbols",
    routine: "routine",
    gardenCart: "garden_cart",
    flood: "flood",
    eggAlt: "egg_alt",
    face5: "face_5",
    cyclone: "cyclone",
    girl: "girl",
    person4: "person_4",
    dentistry: "dentistry",
    tsunami: "tsunami",
    outdoorGarden: "outdoor_garden",
    groupOff: "group_off",
    partlyCloudyNight: "partly_cloudy_night",
    snowing: "snowing",
    severeCold: "severe_cold",
    person3: "person_3",
    tornado: "tornado",
    vapingRooms: "vaping_rooms",
    landslide: "landslide",
    foggy: "foggy",
    woman2: "woman_2",
    safetyDivider: "safety_divider",
    noAdultContent: "no_adult_content",
    volcano: "volcano",
    man2: "man_2",
    "18UpRating": "18_up_rating",
    blind: "blind",
    "6FtApart": "6_ft_apart",
    vapeFree: "vape_free",
    notAccessible: "not_accessible",
    man4: "man_4",
    radiology: "radiology",
    ribCage: "rib_cage",
    bedtimeOff: "bedtime_off",
    handBones: "hand_bones",
    rheumatology: "rheumatology",
    man3: "man_3",
    orthopedics: "orthopedics",
    tibia: "tibia",
    skeleton: "skeleton",
    humerus: "humerus",
    agender: "agender",
    femur: "femur",
    tibiaAlt: "tibia_alt",
    femurAlt: "femur_alt",
    footBones: "foot_bones",
    humerusAlt: "humerus_alt",
    ulnaRadius: "ulna_radius",
    ulnaRadiusAlt: "ulna_radius_alt",
    specificGravity: "specific_gravity",
    diversity4: "diversity_4",
    breastfeeding: "breastfeeding",
    accountCircle: "account_circle",
    info: "info",
    visibility: "visibility",
    calendarMonth: "calendar_month",
    schedule: "schedule",
    language: "language",
    help: "help",
    lock: "lock",
    warning: "warning",
    error: "error",
    visibilityOff: "visibility_off",
    verified: "verified",
    manageAccounts: "manage_accounts",
    taskAlt: "task_alt",
    history: "history",
    event: "event",
    bookmark: "bookmark",
    calendarToday: "calendar_today",
    tipsAndUpdates: "tips_and_updates",
    questionMark: "question_mark",
    fingerprint: "fingerprint",
    lightbulb: "lightbulb",
    category: "category",
    update: "update",
    lockOpen: "lock_open",
    priorityHigh: "priority_high",
    code: "code",
    dateRange: "date_range",
    build: "build",
    uploadFile: "upload_file",
    supervisorAccount: "supervisor_account",
    eventAvailable: "event_available",
    adsClick: "ads_click",
    today: "today",
    settingsSuggest: "settings_suggest",
    touchApp: "touch_app",
    preview: "preview",
    pending: "pending",
    stars: "stars",
    accountBox: "account_box",
    newReleases: "new_releases",
    celebration: "celebration",
    howToReg: "how_to_reg",
    pushPin: "push_pin",
    alarm: "alarm",
    editCalendar: "edit_calendar",
    bugReport: "bug_report",
    editSquare: "edit_square",
    label: "label",
    eventNote: "event_note",
    extension: "extension",
    rateReview: "rate_review",
    hourglassEmpty: "hourglass_empty",
    web: "web",
    recordVoiceOver: "record_voice_over",
    publishedWithChanges: "published_with_changes",
    support: "support",
    notificationImportant: "notification_important",
    helpCenter: "help_center",
    bookmarks: "bookmarks",
    upload: "upload",
    accessibilityNew: "accessibility_new",
    panToolAlt: "pan_tool_alt",
    supervisedUserCircle: "supervised_user_circle",
    collectionsBookmark: "collections_bookmark",
    interests: "interests",
    dangerous: "dangerous",
    allInclusive: "all_inclusive",
    rule: "rule",
    changeHistory: "change_history",
    priority: "priority",
    eventUpcoming: "event_upcoming",
    buildCircle: "build_circle",
    panTool: "pan_tool",
    wysiwyg: "wysiwyg",
    circleNotifications: "circle_notifications",
    hotelClass: "hotel_class",
    api: "api",
    manageHistory: "manage_history",
    accessible: "accessible",
    webAsset: "web_asset",
    upgrade: "upgrade",
    bookmarkAdd: "bookmark_add",
    lockReset: "lock_reset",
    input: "input",
    eventBusy: "event_busy",
    flutterDash: "flutter_dash",
    moreTime: "more_time",
    saveAs: "save_as",
    backup: "backup",
    accessibility: "accessibility",
    dynamicFeed: "dynamic_feed",
    alarmOn: "alarm_on",
    pageview: "pageview",
    modelTraining: "model_training",
    permContactCalendar: "perm_contact_calendar",
    labelImportant: "label_important",
    historyToggleOff: "history_toggle_off",
    squareFoot: "square_foot",
    more: "more",
    swipe: "swipe",
    approval: "approval",
    componentExchange: "component_exchange",
    eventRepeat: "event_repeat",
    assistant: "assistant",
    bookmarkAdded: "bookmark_added",
    appShortcut: "app_shortcut",
    openInBrowser: "open_in_browser",
    unpublished: "unpublished",
    offlineBolt: "offline_bolt",
    notificationAdd: "notification_add",
    freeCancellation: "free_cancellation",
    noAccounts: "no_accounts",
    backgroundReplace: "background_replace",
    anchor: "anchor",
    runningWithErrors: "running_with_errors",
    "3dRotation": "3d_rotation",
    hourglassFull: "hourglass_full",
    lockPerson: "lock_person",
    newLabel: "new_label",
    lockClock: "lock_clock",
    autoDelete: "auto_delete",
    accessibleForward: "accessible_forward",
    addAlert: "add_alert",
    domainVerification: "domain_verification",
    smartButton: "smart_button",
    outbound: "outbound",
    settingsPower: "settings_power",
    tab: "tab",
    handGesture: "hand_gesture",
    chromeReaderMode: "chrome_reader_mode",
    onlinePrediction: "online_prediction",
    editNotifications: "edit_notifications",
    gesture: "gesture",
    generatingTokens: "generating_tokens",
    findReplace: "find_replace",
    lightbulbCircle: "lightbulb_circle",
    backupTable: "backup_table",
    wifiProtectedSetup: "wifi_protected_setup",
    offlinePin: "offline_pin",
    adUnits: "ad_units",
    bookmarkRemove: "bookmark_remove",
    http: "http",
    alarmAdd: "alarm_add",
    pinchZoomOut: "pinch_zoom_out",
    onDeviceTraining: "on_device_training",
    snooze: "snooze",
    codeOff: "code_off",
    batchPrediction: "batch_prediction",
    pinchZoomIn: "pinch_zoom_in",
    commit: "commit",
    hourglassDisabled: "hourglass_disabled",
    settingsOverscan: "settings_overscan",
    voiceOverOff: "voice_over_off",
    personAddDisabled: "person_add_disabled",
    timer10Alt1: "timer_10_alt_1",
    alarmOff: "alarm_off",
    timeAuto: "time_auto",
    updateDisabled: "update_disabled",
    roundedCorner: "rounded_corner",
    allOut: "all_out",
    labelOff: "label_off",
    timer3Alt1: "timer_3_alt_1",
    tabUnselected: "tab_unselected",
    rsvp: "rsvp",
    webAssetOff: "web_asset_off",
    pinInvoke: "pin_invoke",
    pinEnd: "pin_end",
    codeBlocks: "code_blocks",
    approvalDelegation: "approval_delegation",
    mail: "mail",
    call: "call",
    notifications: "notifications",
    send: "send",
    chat: "chat",
    link: "link",
    forum: "forum",
    inventory2: "inventory_2",
    phoneInTalk: "phone_in_talk",
    contactSupport: "contact_support",
    chatBubble: "chat_bubble",
    notificationsActive: "notifications_active",
    alternateEmail: "alternate_email",
    sms: "sms",
    comment: "comment",
    powerSettingsNew: "power_settings_new",
    hub: "hub",
    personSearch: "person_search",
    importContacts: "import_contacts",
    contactMail: "contact_mail",
    contacts: "contacts",
    liveHelp: "live_help",
    forwardToInbox: "forward_to_inbox",
    markEmailUnread: "mark_email_unread",
    lan: "lan",
    contactPhone: "contact_phone",
    modeComment: "mode_comment",
    reviews: "reviews",
    hourglassTop: "hourglass_top",
    inbox: "inbox",
    drafts: "drafts",
    outgoingMail: "outgoing_mail",
    hourglassBottom: "hourglass_bottom",
    markEmailRead: "mark_email_read",
    smsFailed: "sms_failed",
    linkOff: "link_off",
    calendarAddOn: "calendar_add_on",
    phoneEnabled: "phone_enabled",
    addComment: "add_comment",
    speakerNotes: "speaker_notes",
    permPhoneMsg: "perm_phone_msg",
    coPresent: "co_present",
    topic: "topic",
    callEnd: "call_end",
    notificationsOff: "notifications_off",
    cellTower: "cell_tower",
    markChatUnread: "mark_chat_unread",
    scheduleSend: "schedule_send",
    dialpad: "dialpad",
    callMade: "call_made",
    markUnreadChatAlt: "mark_unread_chat_alt",
    unarchive: "unarchive",
    satelliteAlt: "satellite_alt",
    "3p": "3p",
    cancelPresentation: "cancel_presentation",
    markAsUnread: "mark_as_unread",
    moveToInbox: "move_to_inbox",
    attachEmail: "attach_email",
    phonelinkRing: "phonelink_ring",
    nextPlan: "next_plan",
    unsubscribe: "unsubscribe",
    phoneCallback: "phone_callback",
    callReceived: "call_received",
    settingsPhone: "settings_phone",
    callSplit: "call_split",
    presentToAll: "present_to_all",
    addCall: "add_call",
    allInbox: "all_inbox",
    markunreadMailbox: "markunread_mailbox",
    voiceChat: "voice_chat",
    phoneForwarded: "phone_forwarded",
    mailLock: "mail_lock",
    attribution: "attribution",
    voicemail: "voicemail",
    duo: "duo",
    contactEmergency: "contact_emergency",
    markChatRead: "mark_chat_read",
    upcoming: "upcoming",
    outbox: "outbox",
    swapCalls: "swap_calls",
    spoke: "spoke",
    phoneDisabled: "phone_disabled",
    phonelinkLock: "phonelink_lock",
    cancelScheduleSend: "cancel_schedule_send",
    ringVolume: "ring_volume",
    notificationsPaused: "notifications_paused",
    pictureInPictureAlt: "picture_in_picture_alt",
    quickreply: "quickreply",
    commentBank: "comment_bank",
    sendAndArchive: "send_and_archive",
    phoneMissed: "phone_missed",
    settingsBluetooth: "settings_bluetooth",
    chatAddOn: "chat_add_on",
    phonelinkErase: "phonelink_erase",
    pictureInPicture: "picture_in_picture",
    commentsDisabled: "comments_disabled",
    videoChat: "video_chat",
    pausePresentation: "pause_presentation",
    score: "score",
    speakerPhone: "speaker_phone",
    speakerNotesOff: "speaker_notes_off",
    autoReadPlay: "auto_read_play",
    cellWifi: "cell_wifi",
    mms: "mms",
    playForWork: "play_for_work",
    callMerge: "call_merge",
    callMissedOutgoing: "call_missed_outgoing",
    wifiChannel: "wifi_channel",
    callMissed: "call_missed",
    calendarAppsScript: "calendar_apps_script",
    phonePaused: "phone_paused",
    rtt: "rtt",
    autoReadPause: "auto_read_pause",
    phoneLocked: "phone_locked",
    wifiCalling: "wifi_calling",
    dialerSip: "dialer_sip",
    chatAppsScript: "chat_apps_script",
    nat: "nat",
    sip: "sip",
    phoneBluetoothSpeaker: "phone_bluetooth_speaker",
    e911Avatar: "e911_avatar",
    inboxCustomize: "inbox_customize",
    edit: "edit",
    photoCamera: "photo_camera",
    filterAlt: "filter_alt",
    image: "image",
    navigateNext: "navigate_next",
    tune: "tune",
    timer: "timer",
    pictureAsPdf: "picture_as_pdf",
    circle: "circle",
    palette: "palette",
    addAPhoto: "add_a_photo",
    autoAwesome: "auto_awesome",
    photoLibrary: "photo_library",
    magicButton: "magic_button",
    navigateBefore: "navigate_before",
    autoStories: "auto_stories",
    addPhotoAlternate: "add_photo_alternate",
    brush: "brush",
    imagesmode: "imagesmode",
    nature: "nature",
    flashOn: "flash_on",
    wbSunny: "wb_sunny",
    camera: "camera",
    straighten: "straighten",
    looksOne: "looks_one",
    landscape: "landscape",
    timelapse: "timelapse",
    slideshow: "slideshow",
    gridOn: "grid_on",
    rotateRight: "rotate_right",
    cropSquare: "crop_square",
    style: "style",
    adjust: "adjust",
    cropFree: "crop_free",
    aspectRatio: "aspect_ratio",
    brightness6: "brightness_6",
    photo: "photo",
    naturePeople: "nature_people",
    filterVintage: "filter_vintage",
    crop: "crop",
    imageSearch: "image_search",
    blurOn: "blur_on",
    movieFilter: "movie_filter",
    centerFocusStrong: "center_focus_strong",
    contrast: "contrast",
    faceRetouchingNatural: "face_retouching_natural",
    rotateLeft: "rotate_left",
    looksTwo: "looks_two",
    compare: "compare",
    colorize: "colorize",
    flare: "flare",
    wbIncandescent: "wb_incandescent",
    filterNone: "filter_none",
    filterDrama: "filter_drama",
    healing: "healing",
    looks3: "looks_3",
    brightness5: "brightness_5",
    wbTwilight: "wb_twilight",
    invertColors: "invert_colors",
    lens: "lens",
    opacity: "opacity",
    incompleteCircle: "incomplete_circle",
    animation: "animation",
    filterCenterFocus: "filter_center_focus",
    brokenImage: "broken_image",
    addToPhotos: "add_to_photos",
    brightness4: "brightness_4",
    flip: "flip",
    flashOff: "flash_off",
    centerFocusWeak: "center_focus_weak",
    autoAwesomeMotion: "auto_awesome_motion",
    lensBlur: "lens_blur",
    micExternalOn: "mic_external_on",
    details: "details",
    flipCameraAndroid: "flip_camera_android",
    noPhotography: "no_photography",
    grain: "grain",
    panorama: "panorama",
    imageNotSupported: "image_not_supported",
    dehaze: "dehaze",
    webStories: "web_stories",
    loupe: "loupe",
    flaky: "flaky",
    exposurePlus1: "exposure_plus_1",
    gifBox: "gif_box",
    settingsBrightness: "settings_brightness",
    autoAwesomeMosaic: "auto_awesome_mosaic",
    texture: "texture",
    looks4: "looks_4",
    filter1: "filter_1",
    timerOff: "timer_off",
    flipCameraIos: "flip_camera_ios",
    cameraEnhance: "camera_enhance",
    panoramaFishEye: "panorama_fish_eye",
    viewCompact: "view_compact",
    filter: "filter",
    brightness1: "brightness_1",
    controlPointDuplicate: "control_point_duplicate",
    photoAlbum: "photo_album",
    photoCameraFront: "photo_camera_front",
    brightness7: "brightness_7",
    viewComfy: "view_comfy",
    linkedCamera: "linked_camera",
    crop169: "crop_16_9",
    transform: "transform",
    hideImage: "hide_image",
    looks: "looks",
    looks5: "looks_5",
    exposure: "exposure",
    rotate90DegreesCcw: "rotate_90_degrees_ccw",
    brightness3: "brightness_3",
    photoFilter: "photo_filter",
    filterHdr: "filter_hdr",
    crop75: "crop_7_5",
    gif: "gif",
    leakAdd: "leak_add",
    gradient: "gradient",
    hdrStrong: "hdr_strong",
    vrpano: "vrpano",
    blurCircular: "blur_circular",
    cropPortrait: "crop_portrait",
    hdrAuto: "hdr_auto",
    cameraRoll: "camera_roll",
    rotate90DegreesCw: "rotate_90_degrees_cw",
    motionPhotosAuto: "motion_photos_auto",
    brightness2: "brightness_2",
    photoSizeSelectSmall: "photo_size_select_small",
    looks6: "looks_6",
    shutterSpeed: "shutter_speed",
    cameraFront: "camera_front",
    cropLandscape: "crop_landscape",
    filter2: "filter_2",
    flashAuto: "flash_auto",
    filterTiltShift: "filter_tilt_shift",
    monochromePhotos: "monochrome_photos",
    deblur: "deblur",
    hdrWeak: "hdr_weak",
    crop54: "crop_5_4",
    filter4: "filter_4",
    motionPhotosPaused: "motion_photos_paused",
    astrophotographyAuto: "astrophotography_auto",
    crop32: "crop_3_2",
    filter3: "filter_3",
    cropRotate: "crop_rotate",
    nightSightAuto: "night_sight_auto",
    tonality: "tonality",
    switchCamera: "switch_camera",
    photoFrame: "photo_frame",
    exposureZero: "exposure_zero",
    partyMode: "party_mode",
    photoSizeSelectLarge: "photo_size_select_large",
    filterFrames: "filter_frames",
    fluorescent: "fluorescent",
    evShadow: "ev_shadow",
    exposurePlus2: "exposure_plus_2",
    motionBlur: "motion_blur",
    blurLinear: "blur_linear",
    exposureNeg1: "exposure_neg_1",
    photoCameraBack: "photo_camera_back",
    wbIridescent: "wb_iridescent",
    filterBAndW: "filter_b_and_w",
    panoramaHorizontal: "panorama_horizontal",
    motionPhotosOff: "motion_photos_off",
    switchVideo: "switch_video",
    blurMedium: "blur_medium",
    filter5: "filter_5",
    invertColorsOff: "invert_colors_off",
    filter7: "filter_7",
    rawOn: "raw_on",
    burstMode: "burst_mode",
    panoramaPhotosphere: "panorama_photosphere",
    faceRetouchingOff: "face_retouching_off",
    gridOff: "grid_off",
    hdrOn: "hdr_on",
    filter9Plus: "filter_9_plus",
    blurShort: "blur_short",
    filter8: "filter_8",
    filter9: "filter_9",
    wbShade: "wb_shade",
    dirtyLens: "dirty_lens",
    timer10: "timer_10",
    noFlash: "no_flash",
    trailLength: "trail_length",
    imageAspectRatio: "image_aspect_ratio",
    filter6: "filter_6",
    exposureNeg2: "exposure_neg_2",
    vignette: "vignette",
    timer3: "timer_3",
    leakRemove: "leak_remove",
    "60fpsSelect": "60fps_select",
    "30fpsSelect": "30fps_select",
    blurOff: "blur_off",
    micExternalOff: "mic_external_off",
    permCameraMic: "perm_camera_mic",
    panoramaVertical: "panorama_vertical",
    trailLengthMedium: "trail_length_medium",
    trailLengthShort: "trail_length_short",
    autofpsSelect: "autofps_select",
    cameraRear: "camera_rear",
    nightSightAutoOff: "night_sight_auto_off",
    panoramaWideAngle: "panorama_wide_angle",
    mp: "mp",
    hdrEnhancedSelect: "hdr_enhanced_select",
    "24mp": "24mp",
    hdrOnSelect: "hdr_on_select",
    hdrOff: "hdr_off",
    "22mp": "22mp",
    "10mp": "10mp",
    "18mp": "18mp",
    "12mp": "12mp",
    astrophotographyOff: "astrophotography_off",
    wbAuto: "wb_auto",
    "9mp": "9mp",
    hdrAutoSelect: "hdr_auto_select",
    hdrPlus: "hdr_plus",
    "13mp": "13mp",
    "15mp": "15mp",
    rawOff: "raw_off",
    hdrOffSelect: "hdr_off_select",
    "20mp": "20mp",
    "7mp": "7mp",
    "19mp": "19mp",
    "14mp": "14mp",
    "16mp": "16mp",
    hevc: "hevc",
    "5mp": "5mp",
    "6mp": "6mp",
    "8mp": "8mp",
    "11mp": "11mp",
    "23mp": "23mp",
    "2mp": "2mp",
    "3mp": "3mp",
    "21mp": "21mp",
    "17mp": "17mp",
    "4mp": "4mp",
    motionMode: "motion_mode",
    shoppingCart: "shopping_cart",
    payments: "payments",
    shoppingBag: "shopping_bag",
    monitoring: "monitoring",
    creditCard: "credit_card",
    receiptLong: "receipt_long",
    attachMoney: "attach_money",
    storefront: "storefront",
    sell: "sell",
    trendingUp: "trending_up",
    accountBalance: "account_balance",
    database: "database",
    work: "work",
    paid: "paid",
    accountBalanceWallet: "account_balance_wallet",
    analytics: "analytics",
    insights: "insights",
    queryStats: "query_stats",
    store: "store",
    savings: "savings",
    monetizationOn: "monetization_on",
    calculate: "calculate",
    qrCodeScanner: "qr_code_scanner",
    barChart: "bar_chart",
    addShoppingCart: "add_shopping_cart",
    receipt: "receipt",
    accountTree: "account_tree",
    redeem: "redeem",
    currencyExchange: "currency_exchange",
    trendingFlat: "trending_flat",
    shoppingBasket: "shopping_basket",
    qrCode2: "qr_code_2",
    domain: "domain",
    qrCode: "qr_code",
    leaderboard: "leaderboard",
    precisionManufacturing: "precision_manufacturing",
    corporateFare: "corporate_fare",
    currencyRupee: "currency_rupee",
    timeline: "timeline",
    insertChart: "insert_chart",
    euro: "euro",
    wallet: "wallet",
    workHistory: "work_history",
    showChart: "show_chart",
    creditScore: "credit_score",
    meetingRoom: "meeting_room",
    barcodeScanner: "barcode_scanner",
    pieChart: "pie_chart",
    loyalty: "loyalty",
    copyright: "copyright",
    barcode: "barcode",
    conversionPath: "conversion_path",
    trackChanges: "track_changes",
    euroSymbol: "euro_symbol",
    autoGraph: "auto_graph",
    priceCheck: "price_check",
    trendingDown: "trending_down",
    addBusiness: "add_business",
    addCard: "add_card",
    schema: "schema",
    cardMembership: "card_membership",
    currencyBitcoin: "currency_bitcoin",
    priceChange: "price_change",
    productionQuantityLimits: "production_quantity_limits",
    donutLarge: "donut_large",
    tenancy: "tenancy",
    dataExploration: "data_exploration",
    donutSmall: "donut_small",
    bubbleChart: "bubble_chart",
    money: "money",
    contactless: "contactless",
    stackedLineChart: "stacked_line_chart",
    toll: "toll",
    stackedBarChart: "stacked_bar_chart",
    moneyOff: "money_off",
    cases: "cases",
    currencyYen: "currency_yen",
    currencyPound: "currency_pound",
    areaChart: "area_chart",
    removeShoppingCart: "remove_shopping_cart",
    roomPreferences: "room_preferences",
    addChart: "add_chart",
    shop: "shop",
    cardTravel: "card_travel",
    domainAdd: "domain_add",
    groupedBarChart: "grouped_bar_chart",
    legendToggle: "legend_toggle",
    scatterPlot: "scatter_plot",
    creditCardOff: "credit_card_off",
    ssidChart: "ssid_chart",
    mediation: "mediation",
    candlestickChart: "candlestick_chart",
    currencyRuble: "currency_ruble",
    waterfallChart: "waterfall_chart",
    fullStackedBarChart: "full_stacked_bar_chart",
    domainDisabled: "domain_disabled",
    shopTwo: "shop_two",
    nextWeek: "next_week",
    atm: "atm",
    strikethroughS: "strikethrough_s",
    multilineChart: "multiline_chart",
    currencyLira: "currency_lira",
    currencyYuan: "currency_yuan",
    noMeetingRoom: "no_meeting_room",
    currencyFranc: "currency_franc",
    autopay: "autopay",
    contactlessOff: "contactless_off",
    pinDrop: "pin_drop",
    locationOn: "location_on",
    map: "map",
    homePin: "home_pin",
    explore: "explore",
    restaurant: "restaurant",
    flag: "flag",
    myLocation: "my_location",
    localFireDepartment: "local_fire_department",
    personPinCircle: "person_pin_circle",
    localMall: "local_mall",
    nearMe: "near_me",
    whereToVote: "where_to_vote",
    businessCenter: "business_center",
    east: "east",
    restaurantMenu: "restaurant_menu",
    handyman: "handyman",
    factory: "factory",
    localLibrary: "local_library",
    homeWork: "home_work",
    medicalServices: "medical_services",
    layers: "layers",
    localActivity: "local_activity",
    shareLocation: "share_location",
    northEast: "north_east",
    emergency: "emergency",
    addLocation: "add_location",
    fastfood: "fastfood",
    warehouse: "warehouse",
    personPin: "person_pin",
    navigation: "navigation",
    localParking: "local_parking",
    homeRepairService: "home_repair_service",
    south: "south",
    localHospital: "local_hospital",
    localPolice: "local_police",
    zoomOutMap: "zoom_out_map",
    localFlorist: "local_florist",
    locationSearching: "location_searching",
    locationAway: "location_away",
    west: "west",
    crisisAlert: "crisis_alert",
    localGasStation: "local_gas_station",
    mapsUgc: "maps_ugc",
    park: "park",
    cleaningServices: "cleaning_services",
    localAtm: "local_atm",
    package: "package",
    electricalServices: "electrical_services",
    north: "north",
    addLocationAlt: "add_location_alt",
    flagCircle: "flag_circle",
    fmdBad: "fmd_bad",
    theaterComedy: "theater_comedy",
    directions: "directions",
    localDrink: "local_drink",
    localPizza: "local_pizza",
    localPostOffice: "local_post_office",
    wineBar: "wine_bar",
    locationHome: "location_home",
    notListedLocation: "not_listed_location",
    beenhere: "beenhere",
    localConvenienceStore: "local_convenience_store",
    signpost: "signpost",
    locationAutomation: "location_automation",
    tour: "tour",
    altRoute: "alt_route",
    church: "church",
    tripOrigin: "trip_origin",
    traffic: "traffic",
    localLaundryService: "local_laundry_service",
    evStation: "ev_station",
    takeoutDining: "takeout_dining",
    safetyCheck: "safety_check",
    moving: "moving",
    zoomInMap: "zoom_in_map",
    soupKitchen: "soup_kitchen",
    stadium: "stadium",
    transferWithinAStation: "transfer_within_a_station",
    locationOff: "location_off",
    connectingAirports: "connecting_airports",
    editLocation: "edit_location",
    wrongLocation: "wrong_location",
    multipleStop: "multiple_stop",
    pestControl: "pest_control",
    plumbing: "plumbing",
    modeOfTravel: "mode_of_travel",
    minorCrash: "minor_crash",
    southEast: "south_east",
    addRoad: "add_road",
    localPharmacy: "local_pharmacy",
    fireTruck: "fire_truck",
    dryCleaning: "dry_cleaning",
    castle: "castle",
    setMeal: "set_meal",
    babyChangingStation: "baby_changing_station",
    layersClear: "layers_clear",
    mosque: "mosque",
    editLocationAlt: "edit_location_alt",
    northWest: "north_west",
    localCarWash: "local_car_wash",
    editAttributes: "edit_attributes",
    transitEnterexit: "transit_enterexit",
    runCircle: "run_circle",
    satellite: "satellite",
    sos: "sos",
    editRoad: "edit_road",
    southWest: "south_west",
    addHome: "add_home",
    kebabDining: "kebab_dining",
    streetview: "streetview",
    airlineStops: "airline_stops",
    fireHydrant: "fire_hydrant",
    localSee: "local_see",
    addHomeWork: "add_home_work",
    assistWalker: "assist_walker",
    flightClass: "flight_class",
    noMeals: "no_meals",
    removeRoad: "remove_road",
    synagogue: "synagogue",
    fort: "fort",
    templeBuddhist: "temple_buddhist",
    locationDisabled: "location_disabled",
    compassCalibration: "compass_calibration",
    templeHindu: "temple_hindu",
    exploreOff: "explore_off",
    pestControlRodent: "pest_control_rodent",
    nearMeDisabled: "near_me_disabled",
    directionsAlt: "directions_alt",
    pergola: "pergola",
    directionsOff: "directions_off",
    directionsAltOff: "directions_alt_off",
    description: "description",
    contentCopy: "content_copy",
    dashboard: "dashboard",
    editNote: "edit_note",
    menuBook: "menu_book",
    gridView: "grid_view",
    list: "list",
    folder: "folder",
    listAlt: "list_alt",
    inventory: "inventory",
    folderOpen: "folder_open",
    article: "article",
    factCheck: "fact_check",
    attachFile: "attach_file",
    formatListBulleted: "format_list_bulleted",
    assignment: "assignment",
    task: "task",
    checklist: "checklist",
    cloudUpload: "cloud_upload",
    draft: "draft",
    summarize: "summarize",
    feed: "feed",
    draw: "draw",
    cloud: "cloud",
    newspaper: "newspaper",
    viewList: "view_list",
    fileCopy: "file_copy",
    noteAdd: "note_add",
    borderColor: "border_color",
    book: "book",
    historyEdu: "history_edu",
    designServices: "design_services",
    pendingActions: "pending_actions",
    formatQuote: "format_quote",
    postAdd: "post_add",
    requestQuote: "request_quote",
    cloudDownload: "cloud_download",
    dragHandle: "drag_handle",
    contactPage: "contact_page",
    table: "table",
    spaceDashboard: "space_dashboard",
    archive: "archive",
    contentPaste: "content_paste",
    percent: "percent",
    attachment: "attachment",
    assignmentInd: "assignment_ind",
    formatListNumbered: "format_list_numbered",
    assignmentTurnedIn: "assignment_turned_in",
    tag: "tag",
    tableChart: "table_chart",
    stickyNote2: "sticky_note_2",
    dashboardCustomize: "dashboard_customize",
    reorder: "reorder",
    integrationInstructions: "integration_instructions",
    textFields: "text_fields",
    formatBold: "format_bold",
    findInPage: "find_in_page",
    note: "note",
    textSnippet: "text_snippet",
    documentScanner: "document_scanner",
    checklistRtl: "checklist_rtl",
    noteAlt: "note_alt",
    cloudSync: "cloud_sync",
    editDocument: "edit_document",
    permMedia: "perm_media",
    tableRows: "table_rows",
    title: "title",
    cloudDone: "cloud_done",
    tableView: "table_view",
    contentCut: "content_cut",
    notes: "notes",
    subject: "subject",
    cut: "cut",
    dataObject: "data_object",
    functions: "functions",
    formatItalic: "format_italic",
    contentPasteSearch: "content_paste_search",
    formatColorFill: "format_color_fill",
    plagiarism: "plagiarism",
    horizontalRule: "horizontal_rule",
    folderShared: "folder_shared",
    filePresent: "file_present",
    folderCopy: "folder_copy",
    ballot: "ballot",
    formatAlignLeft: "format_align_left",
    teamDashboard: "team_dashboard",
    formatPaint: "format_paint",
    addLink: "add_link",
    readMore: "read_more",
    difference: "difference",
    viewColumn: "view_column",
    cloudOff: "cloud_off",
    viewAgenda: "view_agenda",
    formatSize: "format_size",
    formatUnderlined: "format_underlined",
    toc: "toc",
    verticalAlignTop: "vertical_align_top",
    height: "height",
    verticalAlignBottom: "vertical_align_bottom",
    copyAll: "copy_all",
    viewWeek: "view_week",
    formatColorText: "format_color_text",
    viewModule: "view_module",
    assignmentLate: "assignment_late",
    assignmentReturn: "assignment_return",
    formatAlignCenter: "format_align_center",
    lowPriority: "low_priority",
    folderSpecial: "folder_special",
    segment: "segment",
    calendarViewMonth: "calendar_view_month",
    polyline: "polyline",
    folderZip: "folder_zip",
    square: "square",
    formatAlignRight: "format_align_right",
    breakingNewsAlt1: "breaking_news_alt_1",
    grading: "grading",
    viewHeadline: "view_headline",
    editOff: "edit_off",
    viewQuilt: "view_quilt",
    linearScale: "linear_scale",
    viewCarousel: "view_carousel",
    requestPage: "request_page",
    textIncrease: "text_increase",
    pages: "pages",
    viewSidebar: "view_sidebar",
    textFormat: "text_format",
    formatAlignJustify: "format_align_justify",
    calendarViewWeek: "calendar_view_week",
    hexagon: "hexagon",
    numbers: "numbers",
    docsAddOn: "docs_add_on",
    folderDelete: "folder_delete",
    formatShapes: "format_shapes",
    formsAddOn: "forms_add_on",
    imagesearchRoller: "imagesearch_roller",
    calendarViewDay: "calendar_view_day",
    joinFull: "join_full",
    videoFile: "video_file",
    cloudQueue: "cloud_queue",
    formatListNumberedRtl: "format_list_numbered_rtl",
    fontDownload: "font_download",
    contentPasteGo: "content_paste_go",
    restorePage: "restore_page",
    joinInner: "join_inner",
    rectangle: "rectangle",
    formatColorReset: "format_color_reset",
    verticalSplit: "vertical_split",
    ruleFolder: "rule_folder",
    viewStream: "view_stream",
    cloudCircle: "cloud_circle",
    formatIndentIncrease: "format_indent_increase",
    spellcheck: "spellcheck",
    assignmentReturned: "assignment_returned",
    dataArray: "data_array",
    alignHorizontalLeft: "align_horizontal_left",
    textDecrease: "text_decrease",
    pivotTableChart: "pivot_table_chart",
    deselect: "deselect",
    verticalAlignCenter: "vertical_align_center",
    spaceBar: "space_bar",
    viewDay: "view_day",
    flipToFront: "flip_to_front",
    formatStrikethrough: "format_strikethrough",
    mergeType: "merge_type",
    pentagon: "pentagon",
    borderAll: "border_all",
    joinLeft: "join_left",
    shortText: "short_text",
    shapeLine: "shape_line",
    formatLineSpacing: "format_line_spacing",
    lineWeight: "line_weight",
    horizontalSplit: "horizontal_split",
    formatIndentDecrease: "format_indent_decrease",
    alignHorizontalCenter: "align_horizontal_center",
    joinRight: "join_right",
    snippetFolder: "snippet_folder",
    subtitlesOff: "subtitles_off",
    alignVerticalBottom: "align_vertical_bottom",
    folderOff: "folder_off",
    alignHorizontalRight: "align_horizontal_right",
    glyphs: "glyphs",
    formatClear: "format_clear",
    insertPageBreak: "insert_page_break",
    contentPasteOff: "content_paste_off",
    superscript: "superscript",
    verticalDistribute: "vertical_distribute",
    lineAxis: "line_axis",
    horizontalDistribute: "horizontal_distribute",
    flipToBack: "flip_to_back",
    lineStyle: "line_style",
    function: "function",
    alignVerticalCenter: "align_vertical_center",
    alignVerticalTop: "align_vertical_top",
    margin: "margin",
    clarify: "clarify",
    subscript: "subscript",
    viewArray: "view_array",
    wrapText: "wrap_text",
    borderStyle: "border_style",
    borderClear: "border_clear",
    borderOuter: "border_outer",
    typeSpecimen: "type_specimen",
    textRotateVertical: "text_rotate_vertical",
    padding: "padding",
    ampStories: "amp_stories",
    formsAppsScript: "forms_apps_script",
    borderVertical: "border_vertical",
    textRotationNone: "text_rotation_none",
    formatTextdirectionLToR: "format_textdirection_l_to_r",
    formatOverline: "format_overline",
    docsAppsScript: "docs_apps_script",
    borderHorizontal: "border_horizontal",
    fontDownloadOff: "font_download_off",
    formatTextdirectionRToL: "format_textdirection_r_to_l",
    borderBottom: "border_bottom",
    textRotationAngleup: "text_rotation_angleup",
    borderTop: "border_top",
    borderLeft: "border_left",
    textRotationDown: "text_rotation_down",
    borderInner: "border_inner",
    textRotationAngledown: "text_rotation_angledown",
    textRotateUp: "text_rotate_up",
    borderRight: "border_right",
    formatH1: "format_h1",
    assignmentAdd: "assignment_add",
    formatUnderlinedSquiggle: "format_underlined_squiggle",
    financeChip: "finance_chip",
    formatH2: "format_h2",
    formatParagraph: "format_paragraph",
    formatImageLeft: "format_image_left",
    formatListBulletedAdd: "format_list_bulleted_add",
    formatH3: "format_h3",
    formatH5: "format_h5",
    formatH6: "format_h6",
    viewColumn2: "view_column_2",
    formatImageRight: "format_image_right",
    join: "join",
    formatH4: "format_h4",
    locationChip: "location_chip",
    votingChip: "voting_chip",
    processChart: "process_chart",
    variables: "variables",
    businessChip: "business_chip",
    playArrow: "play_arrow",
    playCircle: "play_circle",
    mic: "mic",
    videocam: "videocam",
    volumeUp: "volume_up",
    pause: "pause",
    musicNote: "music_note",
    libraryBooks: "library_books",
    movie: "movie",
    skipNext: "skip_next",
    speed: "speed",
    replay: "replay",
    volumeOff: "volume_off",
    viewInAr: "view_in_ar",
    pauseCircle: "pause_circle",
    fiberManualRecord: "fiber_manual_record",
    skipPrevious: "skip_previous",
    stop: "stop",
    stopCircle: "stop_circle",
    equalizer: "equalizer",
    subscriptions: "subscriptions",
    videoLibrary: "video_library",
    fastForward: "fast_forward",
    playlistAdd: "playlist_add",
    videoCall: "video_call",
    repeat: "repeat",
    volumeMute: "volume_mute",
    shuffle: "shuffle",
    micOff: "mic_off",
    libraryMusic: "library_music",
    playlistAddCheck: "playlist_add_check",
    podcasts: "podcasts",
    hearing: "hearing",
    fastRewind: "fast_rewind",
    queueMusic: "queue_music",
    soundDetectionDogBarking: "sound_detection_dog_barking",
    videoCameraFront: "video_camera_front",
    subtitles: "subtitles",
    album: "album",
    playPause: "play_pause",
    volumeDown: "volume_down",
    avTimer: "av_timer",
    radio: "radio",
    discoverTune: "discover_tune",
    libraryAddCheck: "library_add_check",
    videocamOff: "videocam_off",
    closedCaption: "closed_caption",
    stream: "stream",
    forward10: "forward_10",
    replayCircleFilled: "replay_circle_filled",
    notStarted: "not_started",
    playlistPlay: "playlist_play",
    replay10: "replay_10",
    fiberNew: "fiber_new",
    brandingWatermark: "branding_watermark",
    recentActors: "recent_actors",
    playlistRemove: "playlist_remove",
    textToSpeech: "text_to_speech",
    interpreterMode: "interpreter_mode",
    slowMotionVideo: "slow_motion_video",
    framePerson: "frame_person",
    playlistAddCheckCircle: "playlist_add_check_circle",
    settingsVoice: "settings_voice",
    videoSettings: "video_settings",
    featuredPlayList: "featured_play_list",
    audioFile: "audio_file",
    lyrics: "lyrics",
    soundDetectionLoudSound: "sound_detection_loud_sound",
    playLesson: "play_lesson",
    hd: "hd",
    repeatOne: "repeat_one",
    callToAction: "call_to_action",
    addToQueue: "add_to_queue",
    musicOff: "music_off",
    videoCameraBack: "video_camera_back",
    highQuality: "high_quality",
    spatialAudioOff: "spatial_audio_off",
    shuffleOn: "shuffle_on",
    playlistAddCircle: "playlist_add_circle",
    hearingDisabled: "hearing_disabled",
    volumeDownAlt: "volume_down_alt",
    featuredVideo: "featured_video",
    replay5: "replay_5",
    repeatOn: "repeat_on",
    queuePlayNext: "queue_play_next",
    artTrack: "art_track",
    airplay: "airplay",
    spatialAudio: "spatial_audio",
    explicit: "explicit",
    forward5: "forward_5",
    "4k": "4k",
    musicVideo: "music_video",
    forward30: "forward_30",
    replay30: "replay_30",
    speechToText: "speech_to_text",
    spatialTracking: "spatial_tracking",
    controlCamera: "control_camera",
    closedCaptionDisabled: "closed_caption_disabled",
    digitalOutOfHome: "digital_out_of_home",
    videoLabel: "video_label",
    fiberSmartRecord: "fiber_smart_record",
    repeatOneOn: "repeat_one_on",
    playDisabled: "play_disabled",
    sd: "sd",
    broadcastOnPersonal: "broadcast_on_personal",
    missedVideoCall: "missed_video_call",
    surroundSound: "surround_sound",
    "10k": "10k",
    removeFromQueue: "remove_from_queue",
    soundDetectionGlassBreak: "sound_detection_glass_break",
    "60fps": "60fps",
    fiberPin: "fiber_pin",
    broadcastOnHome: "broadcast_on_home",
    fiberDvr: "fiber_dvr",
    "4kPlus": "4k_plus",
    "30fps": "30fps",
    videoStable: "video_stable",
    "8k": "8k",
    "1k": "1k",
    privacy: "privacy",
    "2k": "2k",
    "8kPlus": "8k_plus",
    "1kPlus": "1k_plus",
    "7k": "7k",
    "9k": "9k",
    "9kPlus": "9k_plus",
    "5k": "5k",
    "2kPlus": "2k_plus",
    "5kPlus": "5k_plus",
    "6k": "6k",
    "6kPlus": "6k_plus",
    "3k": "3k",
    "7kPlus": "7k_plus",
    "3kPlus": "3k_plus",
    cinematicBlur: "cinematic_blur",
    localShipping: "local_shipping",
    directionsCar: "directions_car",
    flight: "flight",
    directionsRun: "directions_run",
    directionsWalk: "directions_walk",
    flightTakeoff: "flight_takeoff",
    directionsBus: "directions_bus",
    directionsBike: "directions_bike",
    train: "train",
    airportShuttle: "airport_shuttle",
    pedalBike: "pedal_bike",
    directionsBoat: "directions_boat",
    twoWheeler: "two_wheeler",
    agriculture: "agriculture",
    localTaxi: "local_taxi",
    sailing: "sailing",
    electricCar: "electric_car",
    flightLand: "flight_land",
    hail: "hail",
    noCrash: "no_crash",
    commute: "commute",
    motorcycle: "motorcycle",
    carCrash: "car_crash",
    tram: "tram",
    departureBoard: "departure_board",
    subway: "subway",
    electricMoped: "electric_moped",
    turnRight: "turn_right",
    electricScooter: "electric_scooter",
    forkRight: "fork_right",
    directionsSubway: "directions_subway",
    tireRepair: "tire_repair",
    electricBike: "electric_bike",
    rvHookup: "rv_hookup",
    busAlert: "bus_alert",
    turnLeft: "turn_left",
    transportation: "transportation",
    airlines: "airlines",
    uTurnLeft: "u_turn_left",
    taxiAlert: "taxi_alert",
    electricRickshaw: "electric_rickshaw",
    directionsRailway: "directions_railway",
    turnSlightRight: "turn_slight_right",
    uTurnRight: "u_turn_right",
    forkLeft: "fork_left",
    railwayAlert: "railway_alert",
    bikeScooter: "bike_scooter",
    turnSlightLeft: "turn_slight_left",
    turnSharpRight: "turn_sharp_right",
    noTransfer: "no_transfer",
    snowmobile: "snowmobile",
    turnSharpLeft: "turn_sharp_left",
    school: "school",
    campaign: "campaign",
    construction: "construction",
    engineering: "engineering",
    volunteerActivism: "volunteer_activism",
    science: "science",
    sportsEsports: "sports_esports",
    confirmationNumber: "confirmation_number",
    realEstateAgent: "real_estate_agent",
    cake: "cake",
    sportsSoccer: "sports_soccer",
    selfImprovement: "self_improvement",
    air: "air",
    biotech: "biotech",
    water: "water",
    hiking: "hiking",
    architecture: "architecture",
    personalInjury: "personal_injury",
    sportsScore: "sports_score",
    sportsBasketball: "sports_basketball",
    waves: "waves",
    theaters: "theaters",
    sportsTennis: "sports_tennis",
    switchAccount: "switch_account",
    nightsStay: "nights_stay",
    sportsGymnastics: "sports_gymnastics",
    sportsMotorsports: "sports_motorsports",
    backpack: "backpack",
    sportsKabaddi: "sports_kabaddi",
    surfing: "surfing",
    piano: "piano",
    howToVote: "how_to_vote",
    sports: "sports",
    toys: "toys",
    sportsVolleyball: "sports_volleyball",
    sportsMartialArts: "sports_martial_arts",
    sportsBaseball: "sports_baseball",
    camping: "camping",
    kayaking: "kayaking",
    downhillSkiing: "downhill_skiing",
    scoreboard: "scoreboard",
    swords: "swords",
    phishing: "phishing",
    sportsHandball: "sports_handball",
    sportsFootball: "sports_football",
    skateboarding: "skateboarding",
    sportsGolf: "sports_golf",
    sportsCricket: "sports_cricket",
    toysFan: "toys_fan",
    nordicWalking: "nordic_walking",
    rollerSkating: "roller_skating",
    kitesurfing: "kitesurfing",
    rowing: "rowing",
    scubaDiving: "scuba_diving",
    sportsMma: "sports_mma",
    storm: "storm",
    paragliding: "paragliding",
    sportsHockey: "sports_hockey",
    snowboarding: "snowboarding",
    iceSkating: "ice_skating",
    snowshoeing: "snowshoeing",
    sportsRugby: "sports_rugby",
    sledding: "sledding",
    pianoOff: "piano_off",
    noBackpack: "no_backpack",
    cakeAdd: "cake_add",
    phoneIphone: "phone_iphone",
    save: "save",
    smartphone: "smartphone",
    print: "print",
    keyboardArrowDown: "keyboard_arrow_down",
    computer: "computer",
    devices: "devices",
    desktopWindows: "desktop_windows",
    smartDisplay: "smart_display",
    keyboardBackspace: "keyboard_backspace",
    dns: "dns",
    headphones: "headphones",
    smartToy: "smart_toy",
    keyboardArrowRight: "keyboard_arrow_right",
    phoneAndroid: "phone_android",
    memory: "memory",
    liveTv: "live_tv",
    keyboard: "keyboard",
    headsetMic: "headset_mic",
    laptopMac: "laptop_mac",
    keyboardArrowUp: "keyboard_arrow_up",
    tv: "tv",
    deviceThermostat: "device_thermostat",
    mouse: "mouse",
    balance: "balance",
    route: "route",
    pointOfSale: "point_of_sale",
    keyboardArrowLeft: "keyboard_arrow_left",
    keyboardReturn: "keyboard_return",
    laptopChromebook: "laptop_chromebook",
    power: "power",
    laptopWindows: "laptop_windows",
    router: "router",
    developerBoard: "developer_board",
    displaySettings: "display_settings",
    bookOnline: "book_online",
    scale: "scale",
    fax: "fax",
    watch: "watch",
    developerMode: "developer_mode",
    cast: "cast",
    castForEducation: "cast_for_education",
    videogameAsset: "videogame_asset",
    deviceHub: "device_hub",
    straight: "straight",
    screenSearchDesktop: "screen_search_desktop",
    desktopMac: "desktop_mac",
    settingsEthernet: "settings_ethernet",
    settingsInputAntenna: "settings_input_antenna",
    mobileFriendly: "mobile_friendly",
    monitor: "monitor",
    importantDevices: "important_devices",
    tabletMac: "tablet_mac",
    settingsRemote: "settings_remote",
    sendToMobile: "send_to_mobile",
    systemUpdate: "system_update",
    monitorWeight: "monitor_weight",
    screenRotation: "screen_rotation",
    screenShare: "screen_share",
    devicesOther: "devices_other",
    settingsInputComponent: "settings_input_component",
    keyboardAlt: "keyboard_alt",
    speaker: "speaker",
    keyboardTab: "keyboard_tab",
    powerOff: "power_off",
    simCard: "sim_card",
    vibration: "vibration",
    rememberMe: "remember_me",
    merge: "merge",
    screenshotMonitor: "screenshot_monitor",
    connectedTv: "connected_tv",
    browserUpdated: "browser_updated",
    securityUpdateGood: "security_update_good",
    tablet: "tablet",
    sdCard: "sd_card",
    deviceUnknown: "device_unknown",
    castConnected: "cast_connected",
    chargingStation: "charging_station",
    punchClock: "punch_clock",
    tabletAndroid: "tablet_android",
    phonelinkSetup: "phonelink_setup",
    scanner: "scanner",
    settingsInputHdmi: "settings_input_hdmi",
    screenshot: "screenshot",
    stayCurrentPortrait: "stay_current_portrait",
    printDisabled: "print_disabled",
    tapAndPlay: "tap_and_play",
    keyboardHide: "keyboard_hide",
    securityUpdateWarning: "security_update_warning",
    discFull: "disc_full",
    keyboardCapslock: "keyboard_capslock",
    appBlocking: "app_blocking",
    speakerGroup: "speaker_group",
    mobileScreenShare: "mobile_screen_share",
    aod: "aod",
    tty: "tty",
    liftToTalk: "lift_to_talk",
    sdCardAlert: "sd_card_alert",
    addToHomeScreen: "add_to_home_screen",
    earbuds: "earbuds",
    stopScreenShare: "stop_screen_share",
    permDeviceInformation: "perm_device_information",
    headsetOff: "headset_off",
    resetTv: "reset_tv",
    mobileOff: "mobile_off",
    desktopAccessDisabled: "desktop_access_disabled",
    adfScanner: "adf_scanner",
    offlineShare: "offline_share",
    headphonesBattery: "headphones_battery",
    roundaboutRight: "roundabout_right",
    settingsInputSvideo: "settings_input_svideo",
    screenLockPortrait: "screen_lock_portrait",
    settopComponent: "settop_component",
    watchOff: "watch_off",
    dock: "dock",
    smartScreen: "smart_screen",
    stayCurrentLandscape: "stay_current_landscape",
    settingsCell: "settings_cell",
    earbudsBattery: "earbuds_battery",
    powerInput: "power_input",
    noSim: "no_sim",
    screenLockLandscape: "screen_lock_landscape",
    chromecastDevice: "chromecast_device",
    homeMax: "home_max",
    roundaboutLeft: "roundabout_left",
    stayPrimaryPortrait: "stay_primary_portrait",
    developerBoardOff: "developer_board_off",
    tvOff: "tv_off",
    stayPrimaryLandscape: "stay_primary_landscape",
    rampRight: "ramp_right",
    homeMini: "home_mini",
    phonelinkOff: "phonelink_off",
    rampLeft: "ramp_left",
    screenLockRotation: "screen_lock_rotation",
    videogameAssetOff: "videogame_asset_off",
    aodTablet: "aod_tablet",
    darkMode: "dark_mode",
    lightMode: "light_mode",
    wifi: "wifi",
    signalCellularAlt: "signal_cellular_alt",
    password: "password",
    widgets: "widgets",
    pin: "pin",
    rssFeed: "rss_feed",
    storage: "storage",
    batteryFull: "battery_full",
    wifiOff: "wifi_off",
    bluetooth: "bluetooth",
    dvr: "dvr",
    batteryChargingFull: "battery_charging_full",
    thermostat: "thermostat",
    graphicEq: "graphic_eq",
    nightlight: "nightlight",
    battery5Bar: "battery_5_bar",
    signalWifi4Bar: "signal_wifi_4_bar",
    gppMaybe: "gpp_maybe",
    cable: "cable",
    gppBad: "gpp_bad",
    dataUsage: "data_usage",
    battery4Bar: "battery_4_bar",
    batteryFullAlt: "battery_full_alt",
    signalCellular4Bar: "signal_cellular_4_bar",
    radar: "radar",
    airplanemodeActive: "airplanemode_active",
    cameraswitch: "cameraswitch",
    battery0Bar: "battery_0_bar",
    signalDisconnected: "signal_disconnected",
    wallpaper: "wallpaper",
    flashlightOn: "flashlight_on",
    networkCheck: "network_check",
    battery6Bar: "battery_6_bar",
    charger: "charger",
    wifiTethering: "wifi_tethering",
    simCardDownload: "sim_card_download",
    usb: "usb",
    splitscreen: "splitscreen",
    battery3Bar: "battery_3_bar",
    battery1Bar: "battery_1_bar",
    networkWifi3Bar: "network_wifi_3_bar",
    adb: "adb",
    batteryLow: "battery_low",
    batteryAlert: "battery_alert",
    bluetoothSearching: "bluetooth_searching",
    networkWifi: "network_wifi",
    bluetoothConnected: "bluetooth_connected",
    battery2Bar: "battery_2_bar",
    brightnessHigh: "brightness_high",
    "5g": "5g",
    networkCell: "network_cell",
    nfc: "nfc",
    dataSaverOn: "data_saver_on",
    pattern: "pattern",
    bluetoothDisabled: "bluetooth_disabled",
    signalWifiStatusbarNotConnected: "signal_wifi_statusbar_not_connected",
    noiseControlOff: "noise_control_off",
    signalWifiBad: "signal_wifi_bad",
    networkWifi2Bar: "network_wifi_2_bar",
    signalCellular3Bar: "signal_cellular_3_bar",
    signalWifiOff: "signal_wifi_off",
    networkWifi1Bar: "network_wifi_1_bar",
    modeStandby: "mode_standby",
    brightnessLow: "brightness_low",
    brightnessMedium: "brightness_medium",
    batteryVeryLow: "battery_very_low",
    mobiledataOff: "mobiledata_off",
    grid4x4: "grid_4x4",
    signalWifi0Bar: "signal_wifi_0_bar",
    batteryCharging20: "battery_charging_20",
    batteryCharging80: "battery_charging_80",
    flashlightOff: "flashlight_off",
    batteryCharging90: "battery_charging_90",
    batterySaver: "battery_saver",
    signalWifiStatusbarNull: "signal_wifi_statusbar_null",
    settingsSystemDaydream: "settings_system_daydream",
    batteryUnknown: "battery_unknown",
    batteryCharging50: "battery_charging_50",
    signalCellular2Bar: "signal_cellular_2_bar",
    screenRotationAlt: "screen_rotation_alt",
    badgeCriticalBattery: "badge_critical_battery",
    wifiCalling3: "wifi_calling_3",
    "4gMobiledata": "4g_mobiledata",
    signalCellular1Bar: "signal_cellular_1_bar",
    noiseAware: "noise_aware",
    nearbyError: "nearby_error",
    batteryCharging60: "battery_charging_60",
    doNotDisturbOnTotalSilence: "do_not_disturb_on_total_silence",
    wifiLock: "wifi_lock",
    battery20: "battery_20",
    signalCellularConnectedNoInternet0Bar: "signal_cellular_connected_no_internet_0_bar",
    signalCellular0Bar: "signal_cellular_0_bar",
    networkPing: "network_ping",
    edgesensorHigh: "edgesensor_high",
    wifi2Bar: "wifi_2_bar",
    wifiTetheringError: "wifi_tethering_error",
    batteryCharging30: "battery_charging_30",
    brightnessAuto: "brightness_auto",
    airplanemodeInactive: "airplanemode_inactive",
    wifiCalling1: "wifi_calling_1",
    battery50: "battery_50",
    battery30: "battery_30",
    grid3x3: "grid_3x3",
    lteMobiledata: "lte_mobiledata",
    signalCellularAlt2Bar: "signal_cellular_alt_2_bar",
    permDataSetting: "perm_data_setting",
    signalCellularConnectedNoInternet4Bar: "signal_cellular_connected_no_internet_4_bar",
    "1xMobiledata": "1x_mobiledata",
    battery60: "battery_60",
    bluetoothDrive: "bluetooth_drive",
    "4gPlusMobiledata": "4g_plus_mobiledata",
    wifiCalling2: "wifi_calling_2",
    permScanWifi: "perm_scan_wifi",
    battery90: "battery_90",
    mediaBluetoothOn: "media_bluetooth_on",
    signalCellularNodata: "signal_cellular_nodata",
    signalCellularOff: "signal_cellular_off",
    battery80: "battery_80",
    networkLocked: "network_locked",
    signalCellularAlt1Bar: "signal_cellular_alt_1_bar",
    timer10Select: "timer_10_select",
    wifiTetheringOff: "wifi_tethering_off",
    edgesensorLow: "edgesensor_low",
    devicesFold: "devices_fold",
    usbOff: "usb_off",
    wifi1Bar: "wifi_1_bar",
    apkInstall: "apk_install",
    signalCellularNull: "signal_cellular_null",
    ltePlusMobiledata: "lte_plus_mobiledata",
    "3gMobiledata": "3g_mobiledata",
    gMobiledata: "g_mobiledata",
    gridGoldenratio: "grid_goldenratio",
    portableWifiOff: "portable_wifi_off",
    mediaBluetoothOff: "media_bluetooth_off",
    noiseControlOn: "noise_control_on",
    timer3Select: "timer_3_select",
    eMobiledata: "e_mobiledata",
    apkDocument: "apk_document",
    hMobiledata: "h_mobiledata",
    rMobiledata: "r_mobiledata",
    nearbyOff: "nearby_off",
    hPlusMobiledata: "h_plus_mobiledata",
    badge: "badge",
    verifiedUser: "verified_user",
    adminPanelSettings: "admin_panel_settings",
    report: "report",
    security: "security",
    vpnKey: "vpn_key",
    shield: "shield",
    policy: "policy",
    exclamation: "exclamation",
    privacyTip: "privacy_tip",
    assuredWorkload: "assured_workload",
    disabledVisible: "disabled_visible",
    e911Emergency: "e911_emergency",
    enhancedEncryption: "enhanced_encryption",
    privateConnectivity: "private_connectivity",
    addModerator: "add_moderator",
    syncLock: "sync_lock",
    noEncryption: "no_encryption",
    wifiPassword: "wifi_password",
    keyVisualizer: "key_visualizer",
    removeModerator: "remove_moderator",
    reportOff: "report_off",
    apartment: "apartment",
    locationCity: "location_city",
    fitnessCenter: "fitness_center",
    lunchDining: "lunch_dining",
    spa: "spa",
    cottage: "cottage",
    localCafe: "local_cafe",
    hotel: "hotel",
    familyRestroom: "family_restroom",
    beachAccess: "beach_access",
    localBar: "local_bar",
    otherHouses: "other_houses",
    pool: "pool",
    luggage: "luggage",
    liquor: "liquor",
    airplaneTicket: "airplane_ticket",
    casino: "casino",
    sportsBar: "sports_bar",
    bakeryDining: "bakery_dining",
    ramenDining: "ramen_dining",
    nightlife: "nightlife",
    localDining: "local_dining",
    holidayVillage: "holiday_village",
    icecream: "icecream",
    escalatorWarning: "escalator_warning",
    dinnerDining: "dinner_dining",
    museum: "museum",
    foodBank: "food_bank",
    nightShelter: "night_shelter",
    festival: "festival",
    attractions: "attractions",
    golfCourse: "golf_course",
    stairs: "stairs",
    villa: "villa",
    smokeFree: "smoke_free",
    smokingRooms: "smoking_rooms",
    carRental: "car_rental",
    airlineSeatReclineNormal: "airline_seat_recline_normal",
    elevator: "elevator",
    gite: "gite",
    childFriendly: "child_friendly",
    airlineSeatReclineExtra: "airline_seat_recline_extra",
    breakfastDining: "breakfast_dining",
    carRepair: "car_repair",
    cabin: "cabin",
    carpenter: "carpenter",
    brunchDining: "brunch_dining",
    noFood: "no_food",
    houseboat: "houseboat",
    doNotTouch: "do_not_touch",
    riceBowl: "rice_bowl",
    tapas: "tapas",
    wheelchairPickup: "wheelchair_pickup",
    bento: "bento",
    noDrinks: "no_drinks",
    doNotStep: "do_not_step",
    airlineSeatFlat: "airline_seat_flat",
    bungalow: "bungalow",
    airlineSeatIndividualSuite: "airline_seat_individual_suite",
    escalator: "escalator",
    chalet: "chalet",
    noLuggage: "no_luggage",
    airlineSeatLegroomExtra: "airline_seat_legroom_extra",
    airlineSeatFlatAngled: "airline_seat_flat_angled",
    airlineSeatLegroomReduced: "airline_seat_legroom_reduced",
    airlineSeatLegroomNormal: "airline_seat_legroom_normal",
    noStroller: "no_stroller",
    house: "house",
    bed: "bed",
    acUnit: "ac_unit",
    chair: "chair",
    coffee: "coffee",
    electricBolt: "electric_bolt",
    sensors: "sensors",
    childCare: "child_care",
    checkroom: "checkroom",
    backHand: "back_hand",
    grass: "grass",
    emergencyHome: "emergency_home",
    shower: "shower",
    modeFan: "mode_fan",
    kitchen: "kitchen",
    mop: "mop",
    roomService: "room_service",
    styler: "styler",
    thermometer: "thermometer",
    yard: "yard",
    bathtub: "bathtub",
    kingBed: "king_bed",
    roofing: "roofing",
    energySavingsLeaf: "energy_savings_leaf",
    window: "window",
    cooking: "cooking",
    valve: "valve",
    garageHome: "garage_home",
    doorFront: "door_front",
    light: "light",
    garage: "garage",
    foundation: "foundation",
    outdoorGrill: "outdoor_grill",
    tableRestaurant: "table_restaurant",
    dining: "dining",
    deck: "deck",
    weekend: "weekend",
    coffeeMaker: "coffee_maker",
    sensorOccupied: "sensor_occupied",
    flatware: "flatware",
    humidityHigh: "humidity_high",
    fireplace: "fireplace",
    modeNight: "mode_night",
    highlight: "highlight",
    electricMeter: "electric_meter",
    humidityLow: "humidity_low",
    humidityMid: "humidity_mid",
    tvGen: "tv_gen",
    bedroomParent: "bedroom_parent",
    chairAlt: "chair_alt",
    blender: "blender",
    microwave: "microwave",
    ovenGen: "oven_gen",
    singleBed: "single_bed",
    heatPump: "heat_pump",
    scene: "scene",
    bedroomBaby: "bedroom_baby",
    bathroom: "bathroom",
    inHomeMode: "in_home_mode",
    modeOffOn: "mode_off_on",
    hotTub: "hot_tub",
    hardware: "hardware",
    sprinkler: "sprinkler",
    tableBar: "table_bar",
    gasMeter: "gas_meter",
    crib: "crib",
    soap: "soap",
    countertops: "countertops",
    living: "living",
    modeCool: "mode_cool",
    propaneTank: "propane_tank",
    fireExtinguisher: "fire_extinguisher",
    homeIotDevice: "home_iot_device",
    remoteGen: "remote_gen",
    sensorDoor: "sensor_door",
    outlet: "outlet",
    eventSeat: "event_seat",
    faucet: "faucet",
    gate: "gate",
    airware: "airware",
    dishwasherGen: "dishwasher_gen",
    balcony: "balcony",
    energyProgramSaving: "energy_program_saving",
    airFreshener: "air_freshener",
    wash: "wash",
    waterDamage: "water_damage",
    cameraIndoor: "camera_indoor",
    bedroomChild: "bedroom_child",
    houseSiding: "house_siding",
    microwaveGen: "microwave_gen",
    switch: "switch",
    doorSliding: "door_sliding",
    detectorSmoke: "detector_smoke",
    iron: "iron",
    energyProgramTimeUsed: "energy_program_time_used",
    waterHeater: "water_heater",
    desk: "desk",
    umbrella: "umbrella",
    dresser: "dresser",
    doorBack: "door_back",
    doorbell: "doorbell",
    modeFanOff: "mode_fan_off",
    fence: "fence",
    hvac: "hvac",
    cameraOutdoor: "camera_outdoor",
    kettle: "kettle",
    airPurifierGen: "air_purifier_gen",
    emergencyHeat: "emergency_heat",
    stroller: "stroller",
    curtains: "curtains",
    multicooker: "multicooker",
    emergencyShare: "emergency_share",
    sensorsOff: "sensors_off",
    shieldMoon: "shield_moon",
    modeHeatCool: "mode_heat_cool",
    thermostatAuto: "thermostat_auto",
    smartOutlet: "smart_outlet",
    emergencyRecording: "emergency_recording",
    controllerGen: "controller_gen",
    blinds: "blinds",
    rollerShades: "roller_shades",
    dry: "dry",
    blindsClosed: "blinds_closed",
    rollerShadesClosed: "roller_shades_closed",
    propane: "propane",
    sensorWindow: "sensor_window",
    thermostatCarbon: "thermostat_carbon",
    rangeHood: "range_hood",
    blanket: "blanket",
    doorbell3p: "doorbell_3p",
    tvWithAssistant: "tv_with_assistant",
    verticalShadesClosed: "vertical_shades_closed",
    modeHeatOff: "mode_heat_off",
    verticalShades: "vertical_shades",
    curtainsClosed: "curtains_closed",
    modeCoolOff: "mode_cool_off",
    tamperDetectionOff: "tamper_detection_off",
    stadiaController: "stadia_controller",
    tempPreferencesCustom: "temp_preferences_custom",
    doorOpen: "door_open",
    powerRounded: "power_rounded",
    nestEcoLeaf: "nest_eco_leaf",
    nestClockFarsightAnalog: "nest_clock_farsight_analog",
    deviceReset: "device_reset",
    nestRemoteComfortSensor: "nest_remote_comfort_sensor",
    laundry: "laundry",
    batteryHoriz075: "battery_horiz_075",
    shieldWithHeart: "shield_with_heart",
    tempPreferencesEco: "temp_preferences_eco",
    familiarFaceAndZone: "familiar_face_and_zone",
    toolsPowerDrill: "tools_power_drill",
    airwave: "airwave",
    productivity: "productivity",
    batteryHoriz050: "battery_horiz_050",
    nestHeatLinkGen3: "nest_heat_link_gen_3",
    weatherSnowy: "weather_snowy",
    activityZone: "activity_zone",
    evCharger: "ev_charger",
    cleaningBucket: "cleaning_bucket",
    nestRemote: "nest_remote",
    settingsAlert: "settings_alert",
    nestDisplay: "nest_display",
    arrowsMoreUp: "arrows_more_up",
    nestCamIndoor: "nest_cam_indoor",
    nestHeatLinkE: "nest_heat_link_e",
    nestMultiRoom: "nest_multi_room",
    homeStorage: "home_storage",
    nestSecureAlarm: "nest_secure_alarm",
    batteryHoriz000: "battery_horiz_000",
    lightGroup: "light_group",
    nestThermostatGen3: "nest_thermostat_gen_3",
    detectionAndZone: "detection_and_zone",
    nestCamOutdoor: "nest_cam_outdoor",
    mfgNestYaleLock: "mfg_nest_yale_lock",
    toolsPliersWireStripper: "tools_pliers_wire_stripper",
    nestCamIqOutdoor: "nest_cam_iq_outdoor",
    toolsLadder: "tools_ladder",
    floorLamp: "floor_lamp",
    detectorAlarm: "detector_alarm",
    earlyOn: "early_on",
    nestCamIq: "nest_cam_iq",
    nestClockFarsightDigital: "nest_clock_farsight_digital",
    autoSchedule: "auto_schedule",
    autoActivityZone: "auto_activity_zone",
    homeSpeaker: "home_speaker",
    nestMini: "nest_mini",
    houseWithShield: "house_with_shield",
    nestHelloDoorbell: "nest_hello_doorbell",
    homeMaxDots: "home_max_dots",
    zonePersonUrgent: "zone_person_urgent",
    coolToDry: "cool_to_dry",
    nestAudio: "nest_audio",
    nestWifiRouter: "nest_wifi_router",
    nestFarsightWeather: "nest_farsight_weather",
    shieldWithHouse: "shield_with_house",
    motionSensorActive: "motion_sensor_active",
    windowClosed: "window_closed",
    heatPumpBalance: "heat_pump_balance",
    nestDisplayMax: "nest_display_max",
    nestFoundSavings: "nest_found_savings",
    chromecast2: "chromecast_2",
    armingCountdown: "arming_countdown",
    batteryVert050: "battery_vert_050",
    detectorStatus: "detector_status",
    windowOpen: "window_open",
    batteryProfile: "battery_profile",
    selfCare: "self_care",
    arrowsMoreDown: "arrows_more_down",
    nestTrueRadiant: "nest_true_radiant",
    toolsLevel: "tools_level",
    nestThermostatZirconiumEu: "nest_thermostat_zirconium_eu",
    climateMiniSplit: "climate_mini_split",
    zonePersonAlert: "zone_person_alert",
    nestCamWiredStand: "nest_cam_wired_stand",
    detector: "detector",
    nestDetect: "nest_detect",
    nestWifiPoint: "nest_wifi_point",
    quietTime: "quiet_time",
    toolsInstallationKit: "tools_installation_kit",
    nestDoorbellVisitor: "nest_doorbell_visitor",
    batteryVert020: "battery_vert_020",
    nestThermostatSensorEu: "nest_thermostat_sensor_eu",
    nestWifiMistral: "nest_wifi_mistral",
    nestTag: "nest_tag",
    doorSensor: "door_sensor",
    nestConnect: "nest_connect",
    toolsPhillips: "tools_phillips",
    nestSunblock: "nest_sunblock",
    nestThermostatSensor: "nest_thermostat_sensor",
    batteryVert005: "battery_vert_005",
    nestWifiPointVento: "nest_wifi_point_vento",
    nestThermostatEEu: "nest_thermostat_e_eu",
    nestWifiGale: "nest_wifi_gale",
    nestCamFloodlight: "nest_cam_floodlight",
    doorbellChime: "doorbell_chime",
    detectorBattery: "detector_battery",
    detectorCo: "detector_co",
    nestWakeOnApproach: "nest_wake_on_approach",
    toolsFlatHead: "tools_flat_head",
    nestWakeOnPress: "nest_wake_on_press",
    motionSensorAlert: "motion_sensor_alert",
    nestCamMagnetMount: "nest_cam_magnet_mount",
    motionSensorUrgent: "motion_sensor_urgent",
    tamperDetectionOn: "tamper_detection_on",
    windowSensor: "window_sensor",
    zonePersonIdle: "zone_person_idle",
    quietTimeActive: "quiet_time_active",
    tableLamp: "table_lamp",
    nestCamStand: "nest_cam_stand",
    detectorOffline: "detector_offline",
    wallLamp: "wall_lamp",
    nestCamWallMount: "nest_cam_wall_mount",
    nestLocatorTag: "nest_locator_tag",
    motionSensorIdle: "motion_sensor_idle",
    translate: "translate",
    driveFileRenameOutline: "drive_file_rename_outline",
    android: "android",
    gTranslate: "g_translate",
    homeAppLogo: "home_app_logo",
    googlePlusReshare: "google_plus_reshare",
    cloudySnowing: "cloudy_snowing",
    viewInArNew: "view_in_ar_new",
    toolsWrench: "tools_wrench",
    webhook: "webhook",
    driveFolderUpload: "drive_folder_upload",
    clearNight: "clear_night",
    driveFileMove: "drive_file_move",
    modeHeat: "mode_heat",
    atr: "atr",
    errorCircleRounded: "error_circle_rounded",
    quickPhrases: "quick_phrases",
    addToDrive: "add_to_drive",
    wifiFind: "wifi_find",
    polymer: "polymer",
    logoDev: "logo_dev",
    driveFileMoveOutline: "drive_file_move_outline",
    youtubeActivity: "youtube_activity",
    matter: "matter",
    driveFileMoveRtl: "drive_file_move_rtl",
    macroOff: "macro_off",
    googleWifi: "google_wifi",
    phoneInTalkWatchfaceIndicator: "phone_in_talk_watchface_indicator",
    starRateHalf: "star_rate_half",
};

export default IconList;
